<template>
    <div>
        <!-- 选项卡 -->
        <ul class="tab_content">
            <li class="tab_title erpCompanny" @click="tabs">
                <span class="activ_font" style="color: #7781f1; opacity: 1"> 威有ERP企业版 </span>
                <p class="bottom_line1" style="transform: translateX(0px); height: 4px; opacity: 1; background: #7781f1"></p>
            </li>
            <li class="tab_title erpSart" @click="tab2">
                <span class="activ_font" style="color: rgb(51, 51, 51); opacity: 0.8"> 威有ERP旗舰版 </span>
                <p class="bottom_line" style="display: block"></p>
            </li>
            <li class="tab_title cross" @click="tabs3">
                <span class="activ_font" style="color: rgb(51, 51, 51); opacity: 0.8"> 威有WMS</span>
                <p class="bottom_line" style="display: none"></p>
            </li>
        </ul>
        <!-- 被选择的 -->
        <div class="gekai">
            <!-- tab切换内容 -->
            <ul class="tab_changeBox">
                <div class="fuc_bgc"></div>
                <li class="tab_contentBox container">
                    <img src="https://iv.vu818.com/img//qiyeban.png" alt="威有ERP企业版" class="left_picture companyy" />
                    <div class="right_dscipt">
                        <h3 class="right_des_h">威有ERP企业版</h3>
                        <p class="right_des_p">
                            聚焦中大型企业全流程管控，从渠道分销、采购预警、视频验货、智能补货、支付宝对账等多重管理策略，整体提升管理效率
                        </p>
                        <div class="right_des_jumpBox right_des_jumpBox_button" style="background: transparent">
                            <a
                                href="https://iv.vu818.com/erpCompany/"
                                class="detail"
                                rel="noopener"
                                target="_blank"
                                style="color: #7781f1"
                                >查看详情</a
                            >
                        </div>
                    </div>
                </li>
                <li class="tab_contentBox container" style="display: none">
                    <img src="https://iv.vu818.com/img//qijianban.png" alt="威有ERP旗舰版" class="left_picture star_rep" />
                    <div class="right_dscipt">
                        <h3 class="right_des_h">威有ERP旗舰版</h3>
                        <p class="right_des_p">
                            专注大型企业智能化仓库管理OMS+WMS一体化，3D建模精准还原仓库实景时时订单大数据及AI算法，智能创建拣货波次形成更短拣货路径，提升发货效率
                        </p>
                        <div class="right_des_jumpBox right_des_jumpBox_button">
                            <a href="https://iv.vu818.com/erpFlagship/" class="detail" rel="noopener" target="_blank">查看详情</a>
                        </div>
                    </div>
                </li>
                <li class="tab_contentBox container" style="display: none">
                    <img src="https://iv.vu818.com/img//wms.png" alt="威有WMS" class="left_picture accr_Erp" />
                    <div class="right_dscipt" style="padding-top: 103px">
                        <h3 class="right_des_h">威有WMS</h3>
                        <p class="right_des_p">
                            更专业、更科学的仓库规划，流程精细化管理规范仓库作业流程，降低工作难度，提升仓库效率，满足不同行业，不同经营模式的仓库运营管理
                        </p>
                        <div class="right_des_jumpBox right_des_jumpBox_button">
                            <a href="/newWms/" class="detail" rel="noopener" target="_blank">查看详情</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="container">
            <h3 class="mid-title">{{ scene.title }}</h3>
            <p class="mid-discript">
                威有ERP涵盖订单、仓储等核心管理模块，满足电商经营的核心管理需求，助力企业提效降本，规模化发展
            </p>
        </div>
        <ul class="square_Box" v-if="scene.children">
            <li
                class="square_disript scene"
                v-for="el in scene.children.slice(0, 4)"
                :key="el.id"
                @click="$router.push(`/Solution?pid=${el.pid}&id=${el.id}`)"
            >
                <img class="img_act left-qi" :src="el.list_image" :alt="el.title" />
                <div class="fl_right">
                    <h4 class="text_right sence_h4">{{ el.title }}</h4>
                    <p class="">{{ el.intro }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        scene: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    methods: {
        tabs() {
            let line = document.getElementsByClassName("bottom_line1")[0];
            let activ = document.getElementsByClassName("activ_font");

            line.style.transform = "translateX(0px)";
            activ[0].style.color = "#7781f1";
            activ[0].style.opacity = "1";
            activ[1].style.color = "rgb(51, 51, 51)";
            activ[1].style.opacity = "0.8";
            activ[2].style.color = "rgb(51, 51, 51)";
            activ[2].style.opacity = "0.8";
            let tab_contentBox = document.getElementsByClassName("tab_contentBox");
            tab_contentBox[0].style.display = "list-item";
            tab_contentBox[1].style.display = "none";
            tab_contentBox[2].style.display = "none";
        },

        tab2() {
            let line = document.getElementsByClassName("bottom_line1")[0];
            line.style.transform = "translateX(400px)";
            let activ = document.getElementsByClassName("activ_font");
            activ[1].style.color = "#7781f1";
            activ[1].style.opacity = "1";
            activ[0].style.color = "rgb(51, 51, 51)";
            activ[0].style.opacity = "0.8";
            activ[2].style.color = "rgb(51, 51, 51)";
            activ[2].style.opacity = "0.8";
            let tab_contentBox = document.getElementsByClassName("tab_contentBox");
            tab_contentBox[1].style.display = "list-item";
            tab_contentBox[0].style.display = "none";
            tab_contentBox[2].style.display = "none";
        },
        tabs3() {
            let line = document.getElementsByClassName("bottom_line1")[0];
            // let activ=document.getElementsByClassName('activ_font')[2];
            line.style.transform = "translateX(800px)";
            let activ = document.getElementsByClassName("activ_font");
            activ[2].style.color = "#7781f1";
            activ[2].style.opacity = "1";
            activ[0].style.color = "rgb(51, 51, 51)";
            activ[0].style.opacity = "0.8";
            activ[1].style.color = "rgb(51, 51, 51)";
            activ[1].style.opacity = "0.8";
            let tab_contentBox = document.getElementsByClassName("tab_contentBox");
            tab_contentBox[2].style.display = "list-item";
            tab_contentBox[1].style.display = "none";
            tab_contentBox[0].style.display = "none";
        },
    },
};
</script>
<style scoped>
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.tab_content {
    display: flex;
    justify-content: space-around;
    width: 1200px;
    margin: 0 auto;
    list-style: none;
    padding: 0 0;
    cursor: pointer;
}
.tab_title {
    width: 165px;
    text-align: center;
}
.bottom_line {
    width: 165px;
    height: 4px;
    background: #7781f1;
    opacity: 0.9;
    display: block;
    margin: 0 auto;
    margin-top: 14px;
    opacity: 0;
}
.gekai {
    height: 487px;
}
.tab_changeBox {
    margin-top: 38px;
}

.fuc_bgc {
    background-color: #7781f1;
    opacity: 0.03;
    position: absolute;
    z-index: -1;
    height: 487px;
    width: 100%;
}
.container {
    padding: 0;
    margin: 0 auto;
}
.container {
    min-width: 1200px !important;
    margin: 0 auto;
}
.companyy {
    width: 438px;
    height: 352px;
    margin-top: 49px;
}
img {
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}
.square_Box {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.right_dscipt {
    float: right;
    height: 487px;
    padding-top: 103px;
    padding-right: 200px;
}
.right_des_h {
    width: 330px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 550;
    color: #000000;
    /* line-height: 49px; */
    margin-bottom: 38px;
}
.right_des_p {
    width: 352px;
    height: 99px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    opacity: 0.7;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    text-align: justify;
}

.right_des_jumpBox {
    width: 140px;
    height: 40px;
    border: 1px solid #7781f1;
    border-radius: 5px;
    overflow: hidden;
    text-decoration: none;
}
.right_des_jumpBox a {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.detail {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #7781f1;
    line-height: 62px;
}
a {
    text-decoration: none;
}
.star_rep {
    width: 447px;
    height: 334px;
    margin-top: 66px;
    margin-left: 75px;
}
.accr_Erp {
    width: 449px;
    height: 345px;
    margin-left: 90px;
    margin-top: 71px;
}
.right_des_jumpBox a:hover {
    color: white !important;
    background: #7781f1;
}
/* h2 */
.mid-title {
    width: 282px;
    height: 44px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 0 auto;
    /* line-height: 62px; */
    margin-top: 111px;
    margin-bottom: 37px;
    text-align: center;
}

p,
h3 {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.mid-discript {
    width: 934px;
    height: 21px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    margin: 0 auto;
    color: #000000;
    line-height: 34px;
    opacity: 0.6;
    margin-bottom: 76px;
    text-align: center;
}

img {
    border: 0;
    overflow-clip-margin: content-box;
    overflow: clip;
    vertical-align: middle;
}
.img_act {
    height: 327px;
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: center center;
    transform: scale(1.02);
    position: absolute;
}
.square_disript:hover {
    opacity: 1;
    transition: 1.2s;
}

.square_disript {
    position: relative;
    width: 50%;
    height: 327px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    cursor: pointer;
}

.fl_right {
    float: right;
}

.fl_left > p,
.fl_right > p {
    text-align: justify;
    text-justify: inter-ideograph;
}
.text_right {
    text-align: left;
    margin-right: 183px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #222222;
    position: relative;
    z-index: 11;
    float: right;
    width: 376px;
}
.square_disript p {
    width: 478px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 26px;
    opacity: 0.8;
}
.square_disript.individuality {
    border-right: none;
}

.sence_h4 {
    margin-top: 106px;
    margin-bottom: 27px;
}
.text_left {
    position: relative;
    z-index: 15;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #222222;
    margin-left: 42px;
}

.square_disript.effect {
    border-left: none;
    border-right: none;
    border-top: none;
}
.square_disript.Ai {
    border-right: none;
    border-top: none;
}
.left-qi {
    transform-origin: left center;
    left: 0px;
}
.imgright {
    transform-origin: left center;
    left: -20px;
}

.sence_h4 {
    margin-top: 106px;
    margin-bottom: 27px;
}
.square_disript:hover > .img_act {
    opacity: 0.8;
    transition: 3s;
}
</style>
