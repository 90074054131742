<template>
    <div>
        <div class="bottom_box">
            <h3 class="bottom_box_title">即刻开始，让商业更智能</h3>
            <p class="bottom_box_desript">为电商量身定做的电商ERP，助力企业管理数字化升级</p>
            <div class="try" style="background: transparent">
                <span :style="`background-image: url(${getWebData().public_phone});`"></span>
                <a class="try_a" href="#/theErp" rel="noopener" style="color: rgb(255, 255, 255)">申请体验</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ["getWebData"],
};
</script>
<style lang="less" scoped>
.bottom_box {
    box-sizing: border-box;
    padding-top: 50px;
    background: url(https://iv.vu818.com/img//bottom_banner.png) no-repeat;
    height: 240px;
    background-size: cover;
    background-position: center center;
}
.bottom_box_title {
    width: 381px;
    height: 33px;
    margin: 0 auto;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom_box_desript {
    width: 421px;
    height: 17px;
    margin: 0 auto;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    margin-bottom: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.try {
    width: 172px;
    height: 49px;
    border: 1px solid #ffffff;
    margin: 0 auto;
    border-radius: 10px;
    background-color: transparent;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    position: relative;
    &:hover {
        & > span {
            display: block;
            position: absolute;
            width: 100px;
            height: 100px;
            left: calc(50% - 50px);
            top: -110px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-radius: 20px;
        }
    }
}
.try a {
    height: 49px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
