<template>
    <div>
        <!-- 轮播图 -->
        <div style="margin: 0 auto">
            <el-carousel
                v-if="getAdvData(41).children"
                :autoplay="false"
                indicator-position="outside"
                :interval="5000"
                arrow="always"
                height="700px"
            >
                <template v-for="(el, i) in getAdvData(41).children">
                    <el-carousel-item v-if="el.image" :key="el.id">
                        <div class="carousel-item">
                            <a :href="el.url" target="_blank" rel="noopener noreferrer">
                                <div class="rleft" v-if="i != 0">
                                    <h3>提效后端，向管理要利润</h3>
                                    <p>
                                        助力电商管理数智化升级，实现全业务流程软件化、自动化、智能化，做好电商履约，实现精益管理，实时数据反馈，赋能管理者决策
                                    </p>
                                    <a>立即预约演示</a>
                                </div>
                                <video v-if="el.image.indexOf('.mp4') > 0" :src="el.image" muted autoplay loop></video>
                                <img v-else :src="el.image" />
                            </a>
                        </div>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
        <!-- 中间固定部分 -->
        <div class="fixed">
            <div v-for="(item, index) in fixed" :key="index" class="fixed_item">
                <img :src="item" />
            </div>
        </div>
        <!-- 中间部分 -->
        <div class="container">
            <h3 class="mid-title">产品解决方案</h3>
            <p class="mid-discript">
                威有ERP涵盖订单、仓储等核心管理模块，满足电商经营的核心管理需求，助力企业提效降本，规模化发展
            </p>
        </div>
        <conTabs :scene="scene"></conTabs>
        <chang :industry="industry"></chang>
        <good></good>
        <icon :project="project"></icon>
        <mine :article="article"></mine>
        <bottom></bottom>
    </div>
</template>
<script>
import conTabs from "./contTabs.vue";
import chang from "./chang.vue";
import good from "./good.vue";
import icon from "./icon.vue";
import mine from "./mine.vue";
import bottom from "./bottom.vue";
import axios from "axios";

export default {
    name: "tabs",
    inject: ["getAdvData"],
    components: {
        conTabs,
        good,
        chang,
        icon,
        mine,
        bottom,
    },
    data() {
        return {
            fixed: [
                "https://iv.vu818.com/img/CMMI5.png",
                "https://iv.vu818.com/img/yelloStart.png",
                "https://iv.vu818.com/img/keep.png",
                "https://iv.vu818.com/img/order.png",
            ],
            // 底部我们的动态
            article: [],
            // 行业解决方案
            industry: {},
            // logo图
            project: [],
            // 场景解决方案
            scene: {},
        };
    },
    async created() {
        let {
            data: { article, industry, project, scene },
        } = await axios.get("/api/project/erp");
        this.article = article;
        this.industry = industry;
        this.project = project;
        this.scene = scene;
    },
};
</script>
<style lang="less" scoped>
.carousel-item {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    & > a {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: auto;
        }
    }
}
.el-carousel--horizontal {
    overflow: hidden;
}

video {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.rleft {
    width: 453px;
    position: absolute;
    z-index: 2;
    h3 {
        position: relative;
        width: 100%;
        height: 41px;
        font-size: 40px;
        font-weight: 550;
        color: #ffffff;
        margin-bottom: 32px;
        margin-top: 256px;
        text-align: left;
        line-height: 50px;
        top: -100px;
        left: 100px;
    }
    p {
        position: relative;
        width: 100%;
        height: 45px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        opacity: 0.8;
        margin-bottom: 48px;
        display: flex;
        align-items: center;
        text-align: left;
        top: 0;
        left: 100px;
    }
    a {
        display: block;
        width: max-content;
        padding: 10px 20px;
        border: 1px solid white;
        color: white;
        margin-left: 100px;
        margin-top: 50px;
        background: #0064fa;
        float: left;
        border-radius: 10px;
    }
    a:hover {
        color: #0064fa;
        background: white;
    }
}

.fixed {
    height: 129px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed img {
    width: 280px;
    height: 129px;
    box-shadow: 0 0 10rem 0.5rem rgba(202, 147, 76, 0.2);
}

.fixed_item {
    position: relative;
    width: max-content;
    height: max-content;
    margin-right: 30px;
    top: -70px;
    z-index: 2;
}
.fixed_item > img {
    border-radius: 10px;
}

.fixed_item:nth-child(1) {
    margin-left: 50px;
}

.mid-title {
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 39px;
    width: 282px;
    height: 45px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mid-discript {
    margin: 0 auto;
    margin-bottom: 59px;
    width: 996px;
    height: 21px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 34px;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
